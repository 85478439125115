.labelVeliki {
  font-size: 20px;
  font-weight: bold;
  line-height: 0.5;
}

.labelMali {
  font-size: 16px;
  line-height: 0.5;
}

.labelVeliki2 {
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
}

.labelMali2 {
  font-size: 14px;
  line-height: 0.5;
}

.labelVeliki3 {
  font-size: 160px;
  font-weight: bold;
  line-height: 0.5;
}

.labelMali3 {
  font-size: 14px;
  line-height: 0.5;
}

.novi {
  width: 460px;
  height: 460px;
  border: 1px solid green;
  background: "#e0f2f1";
  overflow: scroll;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
}

.meme {
  border: 1px solid green;
  display: inline-block;
  padding: 7px, 7px, 7px, 7px;
  cursor: pointer;
  height: 30px;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-right: 1px;
  margin-left: 1px;
}

input[type="file"] {
  display: none;
}

.clanoviButton {
  width: 390px;
  color: #6e6e6e;
  font-weight: bold;
}

.clanoviButton:hover {
  color: white;
}

.uplateButton {
  width: 335px;
  color: #6e6e6e;
  font-weight: bold;
}

.uplateButton:hover {
  color: white !important;
}

.popup {
  position: absolute;
  margin-top: 50px;
  margin-left: 300px;
}
.popup {
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
}
